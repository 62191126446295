import React from 'react';
import { Helmet } from 'react-helmet';

export default function Meta({ pageName, description }) {
  const title = `Torquedev${pageName ? ` - ${pageName}` : ''}`;
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="robots" content="index, follow" />
    </Helmet>
  );
}
